import { createTheme, CSSInterpolation } from '@mui/material';

declare module '@mui/material/styles' {
    interface Palette {
        text3: Palette['primary'];
    }

    interface PaletteOptions {
        text3?: PaletteOptions['primary'];
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        text3: true;
    }
    interface ButtonPropsVariantOverrides {
        inline: true;
    }
}

declare module '@mui/material/styles' {
    interface TypeText {
        tertiary: string;
        error: string;
        grey60: string;
        contrastText: string;
    }
}

const basicButtonStyle: CSSInterpolation = {
    fontFamily: 'DM Sans',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    textTransform: 'none',
    paddingTop: '16px',
    paddingBottom: '16px',
    paddingLeft: '24px',
    paddingRight: '24px',
};

const inlineButtonStyle: CSSInterpolation = {
    padding: 0,
    lineHeight: 1,
    fontWeight: 700,
    textTransform: 'none',
};

const outlinedButtonStyle: CSSInterpolation = {
    ...basicButtonStyle,
    padding: '13px 20px',
    color: '#3A48E2',
    backgroundColor: 'white',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 700,
    letterSpacing: '-0.2px',
};

interface Palette {
    background: {
        primary: string;
    };
}

interface PaletteOptions {
    background: {
        primary: string;
    };
}

const appTheme = createTheme({
    palette: {
        background: { default: '#F6F6FB', paper: '#ffffff' },
        primary: {
            main: '#3A48E2',
            dark: '#383A47',
            light: 'rgba(121,123,137,0.05)',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#ffffff',
            contrastText: '#ffffff',
            light: '#F6F6FB',
        },
        text3: {
            main: '#ffffff',
            contrastText: '#ffffff',
        },
        text: {
            primary: '#1B1C20',
            grey60: 'rgba(23, 23, 23, 0.6)',
            secondary: '#797B89',
            tertiary: '#383A47',
            error: '#FD6003',
            contrastText: '#ffffff',
        },
    },
    components: {
        MuiButton: {
            variants: [
                {
                    props: { variant: 'inline' },
                    style: inlineButtonStyle,
                },
            ],
            styleOverrides: {
                contained: basicButtonStyle,
                outlined: outlinedButtonStyle,
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: { fontFamily: 'DM Sans' },
                h1: ({ theme }) => ({
                    fontWeight: 700,
                    fontSize: '50px',
                    lineHeight: '60px',
                }),
                h2: ({ theme }) => ({
                    fontWeight: 700,
                    fontSize: '48px',
                    lineHeight: '56px',
                    color: theme.palette.text.primary,
                }),
                h3: ({ theme }) => ({
                    fontWeight: 700,
                    fontSize: '36px',
                    lineHeight: '56px',
                    color: theme.palette.text.primary,
                }),
                h4: ({ theme }) => ({
                    fontWeight: 700,
                    fontSize: '28px',
                    lineHeight: '36px',
                    color: theme.palette.text.primary,
                }),
                h5: ({ theme }) => ({
                    fontWeight: 700,
                    fontSize: '21px',
                    lineHeight: '33px',
                    color: theme.palette.text.primary,
                }),
                h6: ({ theme }) => ({
                    fontFamily: 'DM Sans',
                    fontWeight: 400,
                    fontSize: '18px',
                    lineHeight: '32px',
                    color: theme.palette.text.primary,
                }),
                body1: ({ theme }) => ({
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '24px',
                    color: theme.palette.text.tertiary,
                }),
                body2: ({ theme }) => ({
                    fontWeight: 400,
                    fontSize: '12px',
                    lineHeight: '20px',
                    color: theme.palette.text.secondary,
                }),
            },
        },
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    backgroundColor: '#ffffff',
                    color: '#ffffff',
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    maxWidth: '603px',
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: ({ theme }) => ({
                    color: theme.palette.primary.main,
                }),
            },
        },
    },
});

export default appTheme;
